import React from 'react';

import * as styles from './TextAndListModule.module.scss';

export type TextAndListModuleProps = {
  title: string;
  text: string;
  listItems: Array<{
    title: string;
    titleWithCloserParameter?: string;
    text: string;
  }>;
  closerName?: string;
};

const TextAndListModule = ({
  title,
  text,
  listItems,
  closerName,
}: TextAndListModuleProps): React.ReactElement => {
  return (
    <section className={styles.section}>
      <div className={styles.sectionContainer}>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.titleDivider}></div>
          <p className={styles.text}>{text}</p>
        </div>
        <div className={styles.listContainer}>
          {listItems.map((item, i) => (
            <div key={i} className={styles.listItem}>
              <span className={styles.number}>{i + 1}</span>
              <div className={styles.listItemTextContainer}>
                <h3 className={styles.itemTitle}>
                  {item.titleWithCloserParameter && closerName
                    ? item.titleWithCloserParameter.replace('{closerName}', closerName)
                    : item.title}
                </h3>
                {item.text && <p className={styles.itemText}>{item.text}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TextAndListModule;
