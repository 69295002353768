// extracted by mini-css-extract-plugin
export var closerContainer = "call-booked-module--closerContainer--9bddf";
export var closerImageContainer = "call-booked-module--closerImageContainer--e9261";
export var closerName = "call-booked-module--closerName--57dff";
export var closerSpan = "call-booked-module--closerSpan--51c36";
export var closerTextContainer = "call-booked-module--closerTextContainer--adafc";
export var copyright = "call-booked-module--copyright--1d2bf";
export var footer = "call-booked-module--footer--cd281";
export var footerDivider = "call-booked-module--footerDivider--18d5f";
export var header = "call-booked-module--header--357ff";
export var headerContainer = "call-booked-module--headerContainer--2de8f";
export var logoContainer = "call-booked-module--logoContainer--62c36";
export var logoFooter = "call-booked-module--logoFooter--5e3c0";
export var page = "call-booked-module--page--812bc";
export var textStyleLargeTitle = "call-booked-module--text-style-large-title--cf755";
export var textStyleLargestTitle = "call-booked-module--text-style-largest-title--612fc";
export var textStyleMediumTitle = "call-booked-module--text-style-medium-title--cb455";
export var textStyleSmallTitle = "call-booked-module--text-style-small-title--162e1";
export var title = "call-booked-module--title--3028f";
export var topic = "call-booked-module--topic--95918";
export var videoText = "call-booked-module--videoText--5077e";
export var videoTextContainer = "call-booked-module--videoTextContainer--e5187";