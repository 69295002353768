import BlockContent from '@sanity/block-content-to-react';
import React, { useEffect, useState } from 'react';
import PageSEO from '../../components/PageSEO';
import { LocalizedSEO, SanityImageType } from '../../fragments';
import logoImageUrl from '../../images/logo.svg';

import { graphql } from 'gatsby';
import logoImageWhiteUrl from '../../images/logoWhite.svg';

import CaseStudiesModule, {
  CaseStudiesModuleProps,
} from '../../components/modules/CaseStudiesModule';
import CloserModule from '../../components/modules/CloserModule';
import NumbersListModule, {
  NumbersListModuleProps,
} from '../../components/modules/NumbersListModule';
import TextAndListModule, {
  TextAndListModuleProps,
} from '../../components/modules/TextAndListModule';
import VideoModule from '../../components/modules/VideoModule';
import Image from '../../components/ui/Image';
import { AUTHOR_NAME, COPYRIGHT_DEFAULT_YEAR } from '../../constants';
import serializers from '../../serializers';
import { useGlobalState } from '../../state/globalStateContext';
import { RawPortableText } from '../../types';
import {
  useActivateGoogleOptimize,
  useStoreReferrerOnLoad,
  useStoreUTMParamsOnLoad,
} from '../../utils/hooks';
import { clsx } from '../../utils/utils';
import * as styles from './call-booked.module.scss';

export const query = graphql`
  {
    sanityLpViralCallBookedPage {
      videoSection {
        topic
        title
        titleWithCloserParameter
        _rawText(resolveReferences: { maxDepth: 4 })
        videoUrl
      }
      listSection {
        title
        text
        listItems {
          title
          titleWithCloserParameter
          text
        }
      }

      seo {
        ...SEO
      }
    }
    sanityLpViralScheduleCallPage {
      numbersSection {
        title
        text
        companyResultsToUse
        numbers {
          superTitle
          number
          numberUnit
          subtitle
          description
        }
      }
      caseStudySection {
        title
        caseStudies {
          _id
          title
          category {
            title
          }
          image {
            ...SanityImage
          }
          imageLayout
          resultsNumbers {
            title
            _rawText(resolveReferences: { maxDepth: 4 })
          }
          _rawDescription(resolveReferences: { maxDepth: 4 })
          _rawIntroduction(resolveReferences: { maxDepth: 4 })
          _rawSolution(resolveReferences: { maxDepth: 4 })
        }
      }
    }
    allSanityCloser(filter: { slug: { current: { ne: null } } }) {
      nodes {
        name
        slug {
          current
        }
        image {
          ...SanityImage
        }
        results {
          number
          subtitle
        }
        testimonial {
          testimonial
          author
          position
        }
        scheduleOnceCalendarId
      }
    }
  }
`;

export interface Closer {
  name: string;
  slug: {
    current: string;
  };
  image: SanityImageType;
  results?: Array<{
    number: string;
    subtitle: string;
  }>;
  testimonial?: {
    testimonial: string;
    author: string;
    position: string;
  };
  scheduleOnceCalendarId: string;
}

interface CallBookedPageProps {
  data: {
    sanityLpViralCallBookedPage: {
      videoSection: {
        topic: string;
        title: string;
        titleWithCloserParameter?: string;
        _rawText: RawPortableText;
        videoUrl: string;
      };
      listSection: TextAndListModuleProps;
      seo: LocalizedSEO;
    };
    sanityLpViralScheduleCallPage: {
      numbersSection: NumbersListModuleProps;
      caseStudySection: CaseStudiesModuleProps;
    };
    allSanityCloser: {
      nodes: Array<Closer>;
    };
  };
}

const CallBookedPage = ({ data }: CallBookedPageProps): React.ReactElement => {
  const { listSection, videoSection, seo } = data.sanityLpViralCallBookedPage;
  const { numbersSection, caseStudySection } = data.sanityLpViralScheduleCallPage;

  const [currentYear, setCurrentYear] = useState<number>(COPYRIGHT_DEFAULT_YEAR);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  const { theme } = useGlobalState();
  const isHidden = useActivateGoogleOptimize() || theme === null;
  useStoreUTMParamsOnLoad();
  useStoreReferrerOnLoad();

  const [closer, setCloser] = useState<Closer | null>(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const closerParam = urlSearchParams.get('closer');

    const matchingCloser =
      closerParam && data.allSanityCloser.nodes.find(closer => closer.slug.current === closerParam);

    if (matchingCloser) {
      setCloser(matchingCloser);
    }
  }, []);

  return (
    <main
      style={isHidden ? { opacity: 0 } : undefined}
      className={styles.main}
      data-theme={theme === 'light' ? 'light' : ''}
    >
      <PageSEO defaultTitle="Call Successfully Booked" pageSEO={seo} />

      <div className={styles.page}>
        <header className={clsx(styles.header, styles.stickyHeader)}>
          <div className={styles.headerContainer}>
            <img className={styles.logoContainer} src={logoImageUrl} alt="" />
          </div>
        </header>

        <VideoModule
          className={styles.videoSection}
          videoUrl={videoSection.videoUrl}
          text={
            <div className={styles.videoTextContainer}>
              {closer && (
                <div className={styles.closerContainer}>
                  <div className={styles.closerImageContainer}>
                    <Image image={closer.image} />
                  </div>
                  <div className={styles.closerTextContainer}>
                    <span className={styles.closerSpan}>Meeting with</span>
                    <span className={styles.closerName}>{closer.name}</span>
                  </div>
                </div>
              )}
              <h1 className={styles.topic}>{videoSection.topic}</h1>
              <h2 className={styles.title}>
                {closer && videoSection.titleWithCloserParameter
                  ? videoSection.titleWithCloserParameter.replace('{closerName}', closer.name)
                  : videoSection.title}
              </h2>
              <BlockContent
                renderContainerOnSingleChild
                className={styles.videoText}
                blocks={videoSection._rawText}
                serializers={serializers}
              />
            </div>
          }
          rowLayout
        ></VideoModule>
        <TextAndListModule {...listSection} closerName={closer?.name} />
        {closer && <CloserModule closer={closer} />}
        <NumbersListModule {...numbersSection} />
        <CaseStudiesModule
          title={caseStudySection.title}
          caseStudies={caseStudySection.caseStudies}
        ></CaseStudiesModule>
        <footer className={styles.footer}>
          <img src={logoImageWhiteUrl} alt="Logo" className={styles.logoFooter} />
          <div className={styles.footerDivider}></div>
          <div className={styles.copyright}>
            <span>
              Copyright {currentYear} | All rights reserved to {AUTHOR_NAME}
            </span>
          </div>
        </footer>
      </div>
    </main>
  );
};

export default CallBookedPage;
