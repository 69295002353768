// extracted by mini-css-extract-plugin
export var itemText = "TextAndListModule-module--itemText--fc688";
export var itemTitle = "TextAndListModule-module--itemTitle--db1e4";
export var listContainer = "TextAndListModule-module--listContainer--16571";
export var listItem = "TextAndListModule-module--listItem--99e88";
export var number = "TextAndListModule-module--number--ca570";
export var section = "TextAndListModule-module--section--126fa";
export var sectionContainer = "TextAndListModule-module--sectionContainer--f7b19";
export var text = "TextAndListModule-module--text--c8727";
export var textContainer = "TextAndListModule-module--textContainer--d30f0";
export var textStyleLargeTitle = "TextAndListModule-module--text-style-large-title--d0729";
export var textStyleLargestTitle = "TextAndListModule-module--text-style-largest-title--db804";
export var textStyleMediumTitle = "TextAndListModule-module--text-style-medium-title--4bcf2";
export var textStyleSmallTitle = "TextAndListModule-module--text-style-small-title--a0065";
export var title = "TextAndListModule-module--title--7d341";
export var titleDivider = "TextAndListModule-module--titleDivider--9d370";